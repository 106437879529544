import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { CDN_URL } from '../app.constants';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'app-evse-pdf',
  templateUrl: `./evse-pdf.component.html`,
  styleUrls: ['./evse-pdf.component.scss']
})
export class EvsePdfComponent implements OnInit {
  cdnURL = CDN_URL;
  data: any;
  public componentLocation = 'text';
  color1: any;
  color2: any;
  totalPages: number = 3; // Total number of pages
  breakdownKeys: Array<string> = ['equipment_cost', 'installation_cost'];
  utilityDescription:any = []
  constructor(public appService: AppService) {

  }
  ngOnInit() {
    this.data = JSON.parse(sessionStorage.getItem('pdfData'));
    if (this.data.metaData.hasOwnProperty('colors')){
      this.appService.setProps(this.data.metaData.colors);
    } else {
      this.appService.setColors(this.data.metaData.partner);
    }
    console.log('data', this.data, this.data.metaData.lang, this.data.metaData.lang.electrumLogo);
    this.setUtilityDescription(this.data.cart.incentive)
  }
  // Method to get the current page number
  getCurrentPage(pageIndex: number): string {
    return `Page ${pageIndex + 1} of ${this.totalPages}`;
  }
  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  }
  setUtilityDescription(data: any): void {
    this.utilityDescription = [];
    Object.keys(data).forEach((key: string) => {
      const category = data[key]; // Get the category object (e.g., utilityRebates, taxCredits)

      // Skip "totalAppliedIncentives" and ensure the category is an object
      if (key === "totalAppliedIncentives" || typeof category !== "object") return;

      Object.values(category).forEach((value: any) => {
        if (typeof value !== "object" || !value.display_name) return; // Skip non-relevant entries

        const utilityDesc: any = {};
        utilityDesc.title = value.display_name;
        utilityDesc.text = value.disclaimer_text;
        utilityDesc.cost = value.total;

        this.utilityDescription.push(utilityDesc);
      });
    });
  }
  isNotArray(value: any): boolean {
    return !Array.isArray(value);
  }
 }
