export let COLORS = {
  root: {
    basic: {
      color1: '#0b2d4e',
      color2: '#2d77ce',
      color3: '#e34a5c',
      color4: '#525252',
      color5: '#e9f2fe',
      color6: '#656565',
      color7: 'steelblue',
      color8: '#339900',
    },
    'LG-Solar' : {
      color1: '#333333',
      color2: '#A50034',
      color3: '#666666',
      color4: '#EEEEEE',
      color5: '#666666',
      color6: '#666666',
      color7: '#A50034',
      color8: '#339900'
    },
    'Enervee-SCE' : {
      color1: '#00664F',
      color2: '#F5A54C',
      color3: '#DD2200',
      color4: '#EEEEEE',
      color5: '#666666',
      color6: '#656565',
      color7: '#F5A54C',
      color8: '#339900'
    },
    'Enervee-coned': {
      color1: '#113355',
      color2: '#069BD7',
      color3: '#DD2200',
      color4: '#EEEEEE',
      color5: '#666666',
      color6: '#666666',
      color7: '#069BD7',
      color8: '#339900'
    },
    'Solar Com' : {
      color1: '#896fda',
      color2: '#f57a74',
      color3: '#DD2200',
      color4: '#EEEEEE',
      color5: '#666666',
      color6: '#666666',
      color7: '#f57a74'
    },
    'Hyundai' : {
      color1: '#1C1B1B',
      color2: '#002C5F',
      color3: '#DD2200',
      color4: '#EEEEEE',
      color5: '#666666',
      color6: '#666666',
      color7: '#002C5F',
      color8: '#002C5F'
    },
    'Genesis' : {
      color1: '#AF6249',
      color2: '#000000',
      color3: '#DD2200',
      color4: '#FFFFFF',
      color5: '#666666',
      color6: '#666666',
      color7: '#2D2D2D',
      color8: '#002C5F'
    },
    'Honda' : {
      color1: '#494E52',
      color2: '#DE182E',
      color3: '#DD2200',
      color4: '#EEEEEE',
      color5: '#666666',
      color6: '#666666',
      color7: '#DE182E',
      color8: '#DE182E'
    },
    'Acura' : {
      color1: '#000000',
      color2: '#E82C2A',
      color3: '#DD2200',
      color4: '#EEEEEE',
      color5: '#666666',
      color6: '#666666',
      color7: '#E82C2A',
      color8: '#E82C2A'
    }
  }
};

